import React from "react";
import Head from "next/head";
import { Footer, Header } from "@/components/sultanhorse-ui";
import {
  RiBriefcaseLine,
  RiInstagramLine,
  RiTiktokLine,
  RiUser2Line,
  RiUserLine,
  RiWhatsappLine,
} from "react-icons/ri";
import { useTranslation } from "next-i18next";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";

export async function getStaticProps({ locale }) {
  return {
    props: {
      ...(await serverSideTranslations(locale, ["common"])), // 'common' adalah nama namespace terjemahan
    },
  };
}

function rental() {
  const { t } = useTranslation();

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico?v=2" />
        <meta
          name="description"
          content="Sultan Horse adalah pilihan terbaik untuk rental mobil di Jabodetabek. Kami menawarkan harga termurah, kenyamanan terjamin, dan pelayanan profesional."
        />
        <meta
          name="keywords"
          content="rental mobil, rental mobil Jabodetabek, rental mobil termurah, rental mobil ternyaman, pelayanan profesional, Sultan Horse"
        />
        <meta name="author" content="Sultan Horse" />
        <meta name="robots" content="index, follow" />
        <meta name="canonical" href="https://www.sultanhorse.com" />
        <meta
          property="og:title"
          content="Sultan Horse - Rental Mobil Jabodetabek Termurah dan Ternyaman"
        />
        <meta
          property="og:description"
          content="Sultan Horse adalah pilihan terbaik untuk rental mobil di Jabodetabek. Kami menawarkan harga termurah, kenyamanan terjamin, dan pelayanan profesional."
        />
        <meta
          property="og:image"
          content="https://sultanhorse.com/images/hero.webp"
        />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="1080" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sultanhorse.com" />
        <meta property="og:site_name" content="Sultan Horse" />
        <meta
          name="twitter:title"
          content="Sultan Horse - Rental Mobil Jabodetabek Termurah dan Ternyaman"
        />
        <meta
          name="twitter:description"
          content="Sultan Horse adalah pilihan terbaik untuk rental mobil di Jabodetabek. Kami menawarkan harga termurah, kenyamanan terjamin, dan pelayanan profesional."
        />
        <meta name="twitter:image" content="images/hero.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@SultanHorse" />
        <meta name="twitter:creator" content="@SultanHorse" />
        <meta name="application-name" content="Sultan Horse" />
        <meta name="apple-mobile-web-app-title" content="Sultan Horse" />
        <link
          rel="apple-touch-icon"
          sizes="57x57"
          href="/apple-icon-57x57.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="60x60"
          href="/apple-icon-60x60.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="72x72"
          href="/apple-icon-72x72.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-icon-76x76.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="114x114"
          href="/apple-icon-114x114.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="120x120"
          href="/apple-icon-120x120.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="144x144"
          href="/apple-icon-144x144.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="152x152"
          href="/apple-icon-152x152.png"
        />
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-icon-180x180.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="192x192"
          href="/android-icon-192x192.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="96x96"
          href="/favicon-96x96.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content="/ms-icon-144x144.png" />
        <meta name="theme-color" content="#ffffff" />
        <title>Rental Mobil Jabodetabek Termurah | Sultan Horse</title>
      </Head>

      <Header />
      <div className="px-4 py-40 bg-gray-100 dark:bg-woodsmoke-950 md:px-28 lg:px-28 xl:px-28">
        <h1 className="text-black dark:text-white">{t("carready")} (12)</h1>
        <div className="grid grid-cols-1 gap-6 mt-12 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3">
          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-new-avanza.png"
              className="w-full"
              alt="Toyota New Avanza Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota New Avanza
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 500,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+New+Avanza%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/all-new-xenia.png"
              className="w-full"
              alt="All New Xenia Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                All New Xenia
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 500,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AAll+New+Xenia%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-innova-reborn.png"
              className="w-full"
              alt="Toyota Innova Reborn Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Innova Reborn
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 750,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Innova+Reborn%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/hyundai-ioniq-5.png"
              className="w-full"
              alt="Hyundai Ioniq 5 Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Hyundai Ioniq 5
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV Listrik
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                2-4 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 1,500,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHyundai+IONIQ+5%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-hiace-premio.png"
              className="w-full"
              alt="Toyota Hiace Premio, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Hiace Premio
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Big MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                8-14 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white">
                    Harga terbaik hubungi kami
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2APremio%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-hiace-commuter.png"
              className="w-full"
              alt="Hiace Commuter, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Hiace Commuter
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Big MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                9-15 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white">
                    Harga terbaik hubungi kami
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHiace+Commuter%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-innova-venturer.png"
              className="w-full"
              alt="Toyota Innova Venturer Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Innova Venturer
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-6 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 950,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Innova+Venturer%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/innova-zenix.png"
              className="w-full"
              alt="Innova Zenix Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Innova Zenix
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 950,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AInnova+Zenix%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/mitsubishi-xpander.png"
              className="w-full"
              alt="Mitsubishi Xpander Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Mitsubishi Xpander
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Low SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 550,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMitsubishi+Xpander%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-alphard.png"
              className="w-full"
              alt="Toyota Alphard Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Alphard
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MVP Premium
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-6 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Hubungi Kami
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Alphard%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-fortuner.png"
              className="w-full"
              alt="Toyota Fortuner Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Fortuner
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 1,500,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Fortuner%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/mitsubishi-pajero.png"
              className="w-full"
              alt="Mitsubishi Pajero Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Mitsubishi Pajero
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 1,500,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMitsubishi+Pajero%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/suzuki-ertiga.png"
              className="w-full"
              alt="Suzuki Ertiga Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Suzuki Ertiga
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 450,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+Ertiga%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/hyundai-stargazer.png"
              className="w-full"
              alt="Hyundai Stargazer Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Hyundai Stargazer
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 550,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AHyundai+Stargazer%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-rush.png"
              className="w-full"
              alt="Toyota Rush Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Rush
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 550,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Rush%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/daihatsu-terios.png"
              className="w-full"
              alt="Daihatsu Terios Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Daihatsu Terios
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 550,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ADaihatsu+Terios%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/bmw-i7.png"
              className="w-full"
              alt="BMW I7 Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                BMW I7
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />4{" "}
                {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 15,000,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ABMW+I7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/suzuki-xl-7.png"
              className="w-full"
              alt="Suzuki XL 7 Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Suzuki New XL 7
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Low SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 500,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-veloz.png"
              className="w-full"
              alt="Toyota Veloz Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Veloz
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 550,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AToyota+Veloz+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/toyota-avanza-xenia.png"
              className="w-full"
              alt="Toyota avanza atau xenia Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Toyota Avanza / Daihatsu Xenia
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 450,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/honda-mobilio.png"
              className="w-full"
              alt="Honda Mobilio Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Honda Mobilio
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 450,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/honda-new-hrv.png"
              className="w-full"
              alt="Honda New RV Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Honda New RV
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Compact SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />4{" "}
                {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 950,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/honda-brv.png"
              className="w-full"
              alt="Honda BR-V Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Honda BR-V
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                SUV Crossover Sub-Compact
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                4-7 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 650,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/wuling-air-ev.png"
              className="w-full"
              alt="Wuling Air Ev Semua Warna, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Wuling Air EV
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Mini SUV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />3{" "}
                {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white whitespace-nowrap">
                    Rp. 950,000
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ASuzuki+New+XL+7%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/long-elf.png"
              className="w-full"
              alt="Long Elf, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Long Elf
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Big MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                10-19 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white">
                    Harga terbaik hubungi kami
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2ALong+Elf%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/medium-bus.png"
              className="w-full"
              alt="Medium Bus, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Medium Bus
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Big MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                25-35 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white">
                    Harga terbaik hubungi kami
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMedium+Bus%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>

          <div className="bg-white border dark:bg-woodsmoke-900 rounded-xl border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
            <img
              src="/images/armada/big-bus.png"
              className="w-full"
              alt="Big Bus, tersedia untuk rental di Sultanhorse Rental Mobil"
            />
            <div className="p-6 border-t border-woodsmoke-300 dark:border-woodsmoke-800 hover:border-punga-700">
              <h1 className="text-xl font-semibold text-black dark:text-white">
                Big Bus
              </h1>
              <h2 className="my-3 font-light text-gray-700 dark:text-gray-400">
                Big MPV
              </h2>
              <div className="flex items-center gap-3 py-2 text-black dark:text-white">
                <RiUserLine className="text-punga-700 dark:text-punga-550" />
                50-60 {t("people")}
              </div>
              <div className="flex items-center gap-2 mt-4">
                <div className="w-6/12">
                  <h1 className="text-black dark:text-white">
                    Harga terbaik hubungi kami
                  </h1>
                  <div className="text-sm text-gray-700 dark:text-gray-400">
                    per 12 {t("hour")}
                  </div>
                </div>
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=%2B6287777111155&text=Halo+Admin%2C+Saya+ingin+booking+mobil+%2AMedium+Bus%2A.+Terima+kasih+.+Mohon+berikan+informasi+lebih+lanjut&type=phone_number&app_absent=0"
                  className="flex items-center justify-center w-6/12 gap-1 px-4 py-2 text-center rounded-lg dark:text-punga-550 dark:bg-punga-900 dark:hover:bg-punga-800 text-punga-900 bg-punga-500 hover:bg-punga-600"
                >
                  {t("rent")}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col justify-between mt-12 text-black bg-white border rounded-lg dark:text-white dark:bg-woodsmoke-900 border-woodsmoke-400 dark:border-woodsmoke-800 p-7">
          <h1 className="text-3xl font-semibold">{t("note")}:</h1>
          <br />
          <ul className="flex flex-col gap-3 ml-5 list-disc">
            <li>{t("note1")}</li>
            <li>{t("note2")}</li>
            <li>{t("note3")}</li>
            <li>{t("note4")}</li>
            <li>{t("note5")}</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default rental;
